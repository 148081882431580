import i18nBase, { StringMap } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation as useTranslationBase } from 'react-i18next';

import { config } from 'app/config';

import lt from 'app/locales/lt.json';
import en from 'app/locales/en.json';
import cs from 'app/locales/cs.json';
import de from 'app/locales/de.json';
import es from 'app/locales/es.json';
import fr from 'app/locales/fr.json';

export const defaultNS = 'translation';

export const resources = Object.fromEntries(
  [
    [
      'lt',
      {
        [defaultNS]: lt,
      },
    ] as const,
    [
      'en',
      {
        [defaultNS]: en,
      },
    ] as const,
    [
      'cs',
      {
        [defaultNS]: cs,
      },
    ] as const,
    [
      'de',
      {
        [defaultNS]: de,
      },
    ] as const,
    [
      'es',
      {
        [defaultNS]: es,
      },
    ] as const,
    [
      'fr',
      {
        [defaultNS]: fr,
      },
    ] as const,
  ].filter(([key]) => config.availableLanguages.includes(key))
);

i18nBase
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage'],
    },
    fallbackLng: config.defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

// i18n type override until i18next types are figured out
export const i18n = i18nBase as {
  t: (key: string, options?: StringMap) => string;
};

// useTranslation type override until i18next types are figured out
export const useTranslation = useTranslationBase as any as () => {
  t: (key: string, options?: StringMap) => string;
};
